import {
  computed,
} from "@vue/composition-api"
import useToast from "@/utils/toast"
import {
  replaceRouterQuery,
} from "@/utils/syncUrl"
import {
  values,
} from "@/utils/value"

import state from "../state"
import api from "../api"

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: "캠페인 제목",
      paramName: "title",
    },
    {
      label: "IDX",
      paramName: "idx",
      format: "number",
    },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.campaignList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast("danger", "목록을 가져오는데 실패 했습니다")
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: "idx" },
    { key: "title", label: "제목" },
    { key: "created_at", label: "주문일시" },
  ]

  const changePage = params => {
    state.currentPage = params.page
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
