import state from '../state'

export default () => {
  const turnOnModal = err => {
    state.errorReasonModal.isVisible = true
    if (err?.response?.data?.data != null) {
      const errorData = err?.response?.data?.data
      if (typeof errorData === 'string') {
        state.errorReasonModal.errorReason = errorData
      } else {
        state.errorReasonModal.errorReason = JSON.stringify(errorData, null, 4)
      }
    } else {
      state.errorReasonModal.errorReason = err.toString()
    }
  }
  const turnOffModal = () => {
    state.errorReasonModal.isVisible = false
  }

  return {
    turnOnModal,
    turnOffModal,
  }
}
