export default () => ({
  currentPage: null,
  perPage: null,
  totalRows: null,
  isBusy: false,
  campaignList: [],
  filterParams: {},
  sortBy: "-idx",
  isLoading: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
})
