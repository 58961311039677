<template>
  <div>
    <ErrorReasonModal />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import ErrorReasonModal from "./ErrorReasonModal.vue"

export default defineComponent({
  components: {
    ErrorReasonModal,
  },
  setup() {

  },
})
</script>
